<template>
  <bc-container class="cockpit-execution-instructions-status">
    <cargill-section>
      <cargill-section-header
        class="cockpit-execution-instructions-status__header"
        color="#2B323F"
      >
        {{
          $t('controlTower.pages.cockpitExecution.instructionStatusMultiple')
        }}
      </cargill-section-header>
    </cargill-section>

    <bc-row>
      <bc-col cols="12" md="4" xl="4">
        <cockpit-execution-instructions-status-loading
          :base-filter="filter"
          @update="onUpdate"
        ></cockpit-execution-instructions-status-loading>
      </bc-col>

      <bc-col cols="12" md="4" xl="4">
        <cockpit-execution-instructions-status-stuffing
          :base-filter="filter"
          @update="onUpdate"
        ></cockpit-execution-instructions-status-stuffing>
      </bc-col>

      <bc-col cols="12" md="4" xl="4">
        <cockpit-execution-instructions-status-boarding
          :base-filter="filter"
          @update="onUpdate"
        ></cockpit-execution-instructions-status-boarding>
      </bc-col>
    </bc-row>
  </bc-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BcContainer, BcRow } from '@brain/core'

import {
  CargillSection,
  CargillSectionHeader,
  CargillSectionContent
} from '../cargill-section'
import CockpitExecutionInstructionsStatusLoading from './CockpitExecutionInstructionsStatusLoading.vue'
import CockpitExecutionInstructionsStatusStuffing from './CockpitExecutionInstructionsStatusStuffing.vue'
import CockpitExecutionInstructionsStatusBoarding from './CockpitExecutionInstructionsStatusBoarding.vue'

export default defineComponent({
  name: 'CockpitExecutionInstructionsStatus',
  components: {
    BcContainer,
    BcRow,
    CargillSection,
    CargillSectionHeader,
    CargillSectionContent,
    CockpitExecutionInstructionsStatusLoading,
    CockpitExecutionInstructionsStatusStuffing,
    CockpitExecutionInstructionsStatusBoarding
  },
  props: {
    filter: {
      type: Object,
      required: true
    }
  },
  methods: {
    onUpdate() {
      this.$emit('update')
    }
  }
})
</script>

<style lang="scss" scoped>
.cockpit-execution-instructions-status {
  padding: 10px 0 0;

  &__header {
    margin-bottom: 10px;
  }
}
</style>
