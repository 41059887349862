<template>
  <bc-container class="cockpit-execution">
    <cockpit-execution-notifications
      :filter="filter"
    ></cockpit-execution-notifications>

    <cargill-section>
      <cockpit-execution-header
        :last-update="lastUpdate"
        :filter="filter"
        :filter-definition="filterDefinition"
        @filter-change="onFilterChange"
        @sync="onSync"
      ></cockpit-execution-header>

      <cargill-section-content>
        <cockpit-execution-gerencial-information
          :filter="baseFilter"
          @update="onUpdate"
        ></cockpit-execution-gerencial-information>

        <cockpit-execution-instructions-status
          :filter="baseFilter"
          @update="onUpdate"
        ></cockpit-execution-instructions-status>

        <cockpit-execution-instructions-details>
        </cockpit-execution-instructions-details>
      </cargill-section-content>
    </cargill-section>
  </bc-container>
</template>

<script>
import { BcContainer } from '@brain/core'
import { dashboardUtils } from '@cargill/shared'

import {
  CargillSection,
  CargillSectionContent
} from '../components/cargill-section'
import {
  CockpitExecutionHeader,
  CockpitExecutionNotifications,
  CockpitExecutionInstructionsStatus,
  CockpitExecutionInstructionsDetails,
  CockpitExecutionGerencialInformation
} from '../components/cockpit-execution'

export default {
  name: 'CockpitExecution',
  components: {
    BcContainer,
    CargillSection,
    CargillSectionContent,
    CockpitExecutionHeader,
    CockpitExecutionNotifications,
    CockpitExecutionInstructionsStatus,
    CockpitExecutionInstructionsDetails,
    CockpitExecutionGerencialInformation
  },
  data() {
    return {
      lastUpdate: new Date(),
      filter: {
        unity: dashboardUtils.getUnityTonne().value,
        market: undefined
      }
    }
  },
  methods: {
    onFilterChange(filter) {
      this.filter = filter
      this.lastUpdate = new Date()
    },
    onSync() {
      this.filter = { ...this.filter }
      this.lastUpdate = new Date()
    },
    onUpdate() {
      this.lastUpdate = new Date()
    }
  },
  computed: {
    baseFilter() {
      return {
        unityFilter: this.filter.unity,
        marketFilter: this.filter.market,
        processError: false
      }
    },
    filterDefinition() {
      return [
        {
          name: 'unity',
          label: this.$t('controlTower.pages.cockpitExecution.unityMeasure'),
          type: 'select',
          value: dashboardUtils.getUnities(),
          defaultValue: dashboardUtils.getUnityTonne().value
        },
        {
          name: 'market',
          label: this.$t('controlTower.pages.cockpitExecution.market'),
          type: 'select',
          clearable: true,
          value: dashboardUtils.getMarketTypes(),
          defaultValue: undefined
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
