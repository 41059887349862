<template>
  <div class="instructions-table-container">
    <bc-grid
      ref="gridComponent"
      :gridOptions="{
        getRowHeight,
        rowModelType: 'serverSide',
        ...defaultGridOptions
      }"
      :columnDefs="columns"
      :getItems="getItems"
      :countItems="getDataCount"
      :style="`height: 650px; width: 100%`"
      :mainKeys="mainKeys"
      :detailCellRendererFramework="frameworkCellRenderer"
      :detailCellRendererParams="{
        mainKeys,
        mainKeysLevel,
        parentLevelKey: {},
        keyLevel: this.level,
        metaKey: 'master',
        meta
      }"
      :selectable="false"
      :pageSize="13"
      :customSetSize="customSetSize"
      @rowGroupOpened="onGroupOpened"
      :masterDetail="true"
      mode="serverSide"
      v-if="tableReady"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { createCrudColumns, BcGrid } from '@brain/core'
import { metadataHelper, gridUtils } from '@cargill/shared'

import service from '../../api/cockpitService.js'
import CockpitExecutionInstructionsDetailsTableCellRenderer from './CockpitExecutionInstructionsDetailsTableCellRenderer.vue'
import CockpitExecutionInstructionsDetailsTableFrameworkCellRenderer from './CockpitExecutionInstructionsDetailsTableFrameworkCellRenderer.vue'

export default {
  name: 'CockpitExecutionInstructionsDetailsTable',
  components: {
    BcGrid
  },
  props: {
    mainKeys: [],
    mainKeysLevel: [],
    endpoint: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      meta: {},
      gridData: [],
      columns: [],
      tableReady: false,
      frameworkCellRenderer: null,
      keyLevel: null,
      level: 0,
      currentFilter: '',
      defaultGridOptions: gridUtils.defaultGridOptions
    }
  },
  beforeMount() {
    this.frameworkCellRenderer = Vue.extend(
      CockpitExecutionInstructionsDetailsTableFrameworkCellRenderer
    )
  },
  async mounted() {
    const meta = await service.getMetaData()
    this.level = metadataHelper.defineLevel(
      meta.data['master'],
      this.mainKeysLevel
    )
    this.meta = await this.processMetadata(meta.data)
    this.columns = this.createColumns(meta.data['master'])
    this.tableReady = true
  },
  computed: {
    selectedLevelKeyValue() {
      return this.keyLevel
    }
  },
  methods: {
    customSetSize(aggridOptions) {
      gridUtils.resize(aggridOptions)
    },
    translate(key) {
      return this.$t(`controlTower.pages.cockpitExecution.table.${key}`)
    },
    createColumns(metadata) {
      const crudColumns = createCrudColumns(
        metadata,
        'serverSide',
        this.translate
      ).filter((x) => x.key != 'id' && x.key != 'details')
      metadataHelper.defineCellRendererCockpit(
        crudColumns,
        CockpitExecutionInstructionsDetailsTableCellRenderer
      )
      return crudColumns
    },
    async processMetadata(metadata) {
      const _service = service.createCrudService('cockpitInstruction')
      const _childService = service.createCrudService(
        'cockpitInstruction',
        this.mainKeysLevel[this.childLevel]?.masterName
      )

      this.mainKeysLevel.company.levelService = _childService
      metadataHelper.processServices(metadata['subMaster'], _childService)

      await Promise.all([
        this.buildMetadata(metadata['master'], _service),
        this.buildMetadata(metadata['subMaster'], _childService)
      ])
      return metadata
    },
    async buildMetadata(metadata, service) {
      await metadataHelper.buildMetadata(metadata, service)
    },
    onGroupOpened(event) {
      this.keyLevel = event.data[this.level]
    },
    getRowHeight(params) {
      var isDetailRow = params.node.detail
      if (!isDetailRow) {
        return undefined
      }
      return 300
    },
    getDataCount(filter) {
      const { endpoint } = this.mainKeysLevel[this.level]
      this.currentFilter = filter
      return service.getInstructionTableDetailCount(filter, endpoint, null)
    },
    getItems(params) {
      const { endpoint } = this.mainKeysLevel[this.level]
      return service.getInstructionsTableDetailsData(params, endpoint, null)
    },
    reloadTable() {
      const bcGridRef = this.$refs.gridComponent
      bcGridRef.loadData()
    },
    getCurrentFilter() {
      return this.currentFilter
    }
  },
  provide() {
    return {
      validationResult: {}
    }
  }
}
</script>
<style lang="scss" scoped>
.instructions-table-container {
  padding: 20px 0;
  height: 100%;
}
</style>
