<template>
  <cargill-section layer="3" class="cockpit-execution-instructions-status-base">
    <cargill-section-header
      color="#2B323F"
      class="cockpit-execution-instructions-status-base__header"
    >
      <div>{{ title }}</div>

      <div class="cockpit-execution-instructions-status-base__actions">
        <dashboard-filter
          :filters="filterDefinition"
          :value="filter"
          @input="onApplyFilter"
        ></dashboard-filter>
      </div>
    </cargill-section-header>

    <cargill-section-content>
      <bc-container
        class="cockpit-execution-instructions-status-base__container"
      >
        <bc-row dense>
          <bc-col v-for="kpi in kpis" :key="kpi.title" cols="6" md="12" xl="6">
            <dashboard-kpi small :kpi="kpi"></dashboard-kpi>
          </bc-col>
        </bc-row>
        <bc-row dense>
          <bc-col>
            <high-chart
              v-if="chart.chartInput"
              new-layout
              :is-stacked="false"
              :chartInputData="chart.chartInput"
              :unity="unity"
            ></high-chart>
          </bc-col>
        </bc-row>
      </bc-container>
    </cargill-section-content>
  </cargill-section>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BcContainer, BcRow, BcCol } from '@brain/core'
import { dashboardUtils } from '@cargill/shared'

import {
  CargillSection,
  CargillSectionHeader,
  CargillSectionContent
} from '../cargill-section'
import { DashboardKpi, DashboardFilter } from '../dashboards'
import HighChart from '../charts/HighChart.vue'

export default defineComponent({
  name: 'CockpitExecutionInstructionsStatusBase',
  components: {
    BcContainer,
    BcRow,
    BcCol,
    HighChart,
    DashboardKpi,
    DashboardFilter,
    CargillSection,
    CargillSectionHeader,
    CargillSectionContent
  },
  props: {
    title: {
      type: String,
      required: true
    },
    gaugeLabel: {
      type: String,
      required: true
    },
    updateEventName: {
      type: String,
      required: true
    },
    onLoad: {
      type: Function,
      required: true
    },
    baseFilter: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      kpis: [],
      unity: undefined,
      chart: {},
      filter: {},
      filterDefinition: []
    }
  },
  async mounted() {
    await this.loadData()
    this.prepareFilterDefinition()

    if (this.$controlTowerNotification) {
      this.$controlTowerNotification.on(this.updateEventName, this.loadData)
    }
  },
  beforeDestroy() {
    if (this.$controlTowerNotification) {
      this.$controlTowerNotification.off(this.updateEventName, this.loadData)
    }
  },
  methods: {
    prepareFilterDefinition() {
      this.filterDefinition = this.chart.filters

      const marketTypeFilter = this.filterDefinition.find(
        (x) => x.name == 'marketFilter'
      )
      if (marketTypeFilter) {
        marketTypeFilter.value = dashboardUtils.getMarketTypes()
      }
    },
    async loadData() {
      const filter = {
        ...this.baseFilter,
        ...this.filter,
        marketFilter: this.filter.marketFilter
          ? this.filter?.marketFilter
          : this.baseFilter?.marketFilter
      }

      const result = await this.onLoad(filter)

      this.unity = result.unity
      this.chart = dashboardUtils.processChartInput(result.chart, this.chart)
      this.kpis = [this.toGaugeKpi(result), this.toStatusKpi(result.status)]
    },
    toStatusKpi(status) {
      return {
        title: this.$t('controlTower.pages.cockpitExecution.instructionStatus'),
        type: 'status',
        ...status
      }
    },
    toGaugeKpi(data) {
      return {
        title: this.gaugeLabel,
        type: 'gauge',
        value: data.volume,
        unity: data.unity,
        gague: data.value
      }
    },
    onApplyFilter(filter) {
      this.filter = Object.entries(filter)
        .filter(([, value]) => value !== null && value !== undefined)
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
      this.loadData()
    }
  },
  watch: {
    baseFilter: {
      deep: true,
      handler() {
        this.loadData()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.cockpit-execution-instructions-status-base {
  &__header {
    display: flex;
    justify-content: space-between;
    padding-right: 12px;
  }

  &__container {
    padding: 0;
  }
}
</style>
