<template>
  <cargill-section layer="3" class="cockpit-excecution-instructions-details">
    <cargill-section-header
      color="#2B323F"
      class="cockpit-excecution-instructions-details__header"
    >
      <div>
        {{ $t('controlTower.pages.cockpitExecution.instructionsDetailing') }}
      </div>

      <div class="cockpit-excecution-instructions-details__header__actions">
        <cargill-section-header-btn color="link-gray" @click="onExport">
          {{ $t('controlTower.pages.cockpitExecution.exportDetails') }}
        </cargill-section-header-btn>
      </div>
    </cargill-section-header>

    <cargill-section-content>
      <cockpit-execution-instructions-details-legend></cockpit-execution-instructions-details-legend>

      <cockpit-execution-instructions-details-table
        ref="instructionsTable"
        :mainKeys="mainKeys"
        :mainKeysLevel="mainKeysLevel"
        endpoint="getInstructionsTableDetails"
      ></cockpit-execution-instructions-details-table>
    </cargill-section-content>
  </cargill-section>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import downloadFile from 'js-file-download'

import {
  CargillSection,
  CargillSectionHeader,
  CargillSectionContent,
  CargillSectionHeaderBtn
} from '../cargill-section'
import service from '../../api/cockpitService.js'
import CockpitExecutionInstructionsDetailsTable from './CockpitExecutionInstructionsDetailsTable.vue'
import CockpitExecutionInstructionsDetailsLegend from './CockpitExecutionInstructionsDetailsLegend.vue'

export default defineComponent({
  name: 'CockpitExcecutionInstructionsDetails',
  components: {
    CargillSection,
    CargillSectionHeader,
    CargillSectionContent,
    CargillSectionHeaderBtn,
    CockpitExecutionInstructionsDetailsTable,
    CockpitExecutionInstructionsDetailsLegend
  },
  data() {
    return {
      mainKeys: ['instruction', 'company'], // table levels and its keys
      mainKeysLevel: {
        instruction: {
          endpoint: 'getInstructionsTableDetails', // endpoint of that key level
          parentData: [] // keys of this level group data
        },
        company: {
          endpoint: 'getFirstLevelInstructionsTableDetails',
          parentData: ['instruction']
        },
        contractedLotVolume: {
          endpoint: 'getSecondLevelInstructionsTableDetails',
          parentData: [
            'cropYear',
            'company',
            'instruction',
            'planId',
            'vehiclePlate'
          ]
        }
      }
    }
  },
  async mounted() {
    if (this.$controlTowerNotification) {
      this.$controlTowerNotification.on(
        'UpdateInstructionsTableDetails',
        this.reloadData
      )
    }
  },
  beforeDestroy() {
    if (this.$controlTowerNotification) {
      this.$controlTowerNotification.off(
        'UpdateInstructionsTableDetails',
        this.reloadData
      )
    }
  },
  methods: {
    async onExport() {
      const currentFilter = this.instructionsTable.getCurrentFilter()
      const result = await service.exportDetails(currentFilter)
      downloadFile(result.data, result.filename ?? `cockpitdataexport.xlsx`)
    },
    async reloadData() {
      this.instructionsTable.reloadTable()
    }
  },
  computed: {
    instructionsTable() {
      return this.$refs.instructionsTable
    }
  }
})
</script>

<style lang="scss" scoped>
.cockpit-excecution-instructions-details {
  padding-top: 10px;

  &__header {
    display: flex;
    justify-content: space-between;
  }
}
</style>
