<template>
  <div layer="3" class="cockpit-execution-alerts">
    <bc-alert
      v-for="notification in notifications"
      :key="notification.id"
      :type="mapType(notifications.type)"
      dismissible
    >
      {{ notifications.message }}
    </bc-alert>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BcAlert } from '@brain/core'

import service from '../../api/cockpitService.js'

export default defineComponent({
  name: 'CockpitExecutionAlerts',
  components: {
    BcAlert
  },
  props: {
    filter: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      notifications: []
    }
  },
  async mounted() {
    await this.loadNotifications()
  },
  methods: {
    mapType(type) {
      if (type == 'information') {
        return 'info'
      }
      return type
    },
    async loadNotifications() {
      this.notifications = await service.getCockpitNotifications(this.filter)
    }
  }
})
</script>
