<template>
  <div class="cell">
    <div :class="`status ${this.color}`"></div>
    <div>{{ this.value }}</div>
  </div>
</template>

<script>
export default {
  name: 'CockpitExecutionInstructionsDetailsTableCellRenderer',
  data() {
    return {
      value: '',
      color: ''
    }
  },
  beforeMount() {
    if (this.params.value != undefined) {
      this.value = this.params.value.value
      this.color = this.params.value.color
    } else {
      const key = this.params.column.colId
      const { value, color } = this.params.data[key]
      this.value = value
      this.color = color
    }
  }
}
</script>

<style lang="scss" scoped>
.cell {
  display: flex;
  align-items: center;
  gap: 10px;
}

.status {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  &.grey {
    background: #959db5 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #959db580;
  }
  &.green {
    background: #81dd1f 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #81dd1f80;
  }
  &.orange {
    background: #ff9a00 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #ff9a0080;
  }
  &.red {
    background: #de0b3c 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #de0b3c80;
  }
}
</style>
