<template>
  <cargill-section
    layer="3"
    class="cockpit-execution-gerencial-information-week"
  >
    <cargill-section-header color="#2B323F">
      {{ $t('controlTower.pages.cockpitExecution.currentWeek') }}
    </cargill-section-header>
    <cargill-section-content>
      <bc-container
        class="cockpit-execution-gerencial-information-week__container"
      >
        <bc-row dense>
          <bc-col v-for="kpi in kpis" :key="kpi.title" cols="6" lg="6" xl="3">
            <dashboard-kpi small :kpi="kpi"></dashboard-kpi>
          </bc-col>
        </bc-row>
      </bc-container>
    </cargill-section-content>
  </cargill-section>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BcContainer, BcRow, BcCol } from '@brain/core'

import {
  CargillSection,
  CargillSectionHeader,
  CargillSectionContent
} from '../cargill-section'
import { DashboardKpi } from '../dashboards'
import service from '../../api/cockpitService.js'

export default defineComponent({
  name: 'CockpitExecutionGerencialInformationWeek',
  components: {
    BcContainer,
    BcRow,
    BcCol,
    DashboardKpi,
    CargillSection,
    CargillSectionHeader,
    CargillSectionContent
  },
  props: {
    filter: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      kpis: []
    }
  },
  async mounted() {
    await this.loadData()
    if (this.$controlTowerNotification) {
      this.$controlTowerNotification.on('UpdateActualWeek', this.loadData)
    }
  },
  beforeDestroy() {
    if (this.$controlTowerNotification) {
      this.$controlTowerNotification.off('UpdateActualWeek', this.loadData)
    }
  },
  methods: {
    async loadData() {
      const actualWeek = await service.getActualWeek(this.filter)

      this.kpis = [
        this.toWeeklyPlanKpi(
          this.$t('controlTower.pages.cockpitExecution.weeklyPlannedVolume'),
          actualWeek.weeklyPlannedVolume
        ),
        this.toWeeklyPlanKpi(
          this.$t(
            'controlTower.pages.cockpitExecution.weeklyPlannedInstructions'
          ),
          actualWeek.weeklyPlannedInstructions
        ),
        this.toRoadFreightKpi(actualWeek.roadFreight),
        this.toStatusKpi(
          this.$t('controlTower.pages.cockpitExecution.instructionStatus'),
          actualWeek.instructionStatus
        )
      ]

      this.$emit('update')
    },
    toWeeklyPlanKpi(title, data) {
      return {
        title: title,
        type: 'weeklyPlan',
        showLegend: true,
        details: [
          {
            title: this.$t('controlTower.pages.cockpitExecution.loading'),
            value: data.loading.actual,
            planned: data.loading.planned
          },
          {
            title: this.$t('controlTower.pages.cockpitExecution.stuffing'),
            value: data.stuffing.actual,
            planned: data.stuffing.planned
          },
          {
            title: this.$t('controlTower.pages.cockpitExecution.boarding'),
            value: data.boarding.actual,
            planned: data.boarding.planned
          }
        ]
      }
    },
    toRoadFreightKpi(data) {
      return {
        title: this.$t('controlTower.pages.cockpitExecution.roadFreight'),
        type: 'weeklyPlan',
        details: [
          {
            title: this.$t(
              'controlTower.pages.cockpitExecution.contractBoogey'
            ),
            percentage: data.contractBoogey
          },
          {
            title: this.$t('controlTower.pages.cockpitExecution.hired'),
            value: data.hired,
            prefix: 'R$'
          },
          {
            title: this.$t('controlTower.pages.cockpitExecution.boogey'),
            value: data.boogey,
            prefix: 'R$'
          }
        ]
      }
    },
    toStatusKpi(title, data) {
      return {
        title: title,
        type: 'status',
        ...data
      }
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.loadData()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.cockpit-execution-gerencial-information-week {
  &__container {
    padding: 0;
  }
}
</style>
