<template>
  <cargill-section
    layer="3"
    class="cockpit-execution-gerencial-information-accumulated"
  >
    <cargill-section-header color="#2B323F">
      {{ $t('controlTower.pages.cockpitExecution.accumulatedFulfilledDemand') }}
    </cargill-section-header>
    <cargill-section-content>
      <bc-container
        class="cockpit-execution-gerencial-information-accumulated__container"
      >
        <bc-row dense>
          <bc-col v-for="kpi in kpis" :key="kpi.title" cols="6" lg="12" xl="6">
            <dashboard-kpi small :kpi="kpi"></dashboard-kpi>
          </bc-col>
        </bc-row>
      </bc-container>
    </cargill-section-content>
  </cargill-section>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BcContainer, BcRow, BcCol } from '@brain/core'

import {
  CargillSection,
  CargillSectionHeader,
  CargillSectionContent
} from '../cargill-section'
import { DashboardKpi } from '../dashboards'
import service from '../../api/cockpitService.js'

export default defineComponent({
  name: 'CockpitExecutionGerencialInformationAccumulated',
  components: {
    BcContainer,
    BcRow,
    BcCol,
    DashboardKpi,
    CargillSection,
    CargillSectionHeader,
    CargillSectionContent
  },
  props: {
    filter: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      kpis: []
    }
  },
  async mounted() {
    await this.loadData()
    if (this.$controlTowerNotification) {
      this.$controlTowerNotification.on('UpdateFulfilledDemand', this.loadData)
    }
  },
  beforeDestroy() {
    if (this.$controlTowerNotification) {
      this.$controlTowerNotification.off('UpdateFulfilledDemand', this.loadData)
    }
  },
  methods: {
    async loadData() {
      const accumulated = await service.getFulfilledDemand(this.filter)
      this.kpis = [
        this.mapToKpi(
          this.$t('controlTower.pages.cockpitExecution.fiscalYear'),
          accumulated.fiscalYear
        ),
        this.mapToKpi(
          this.$t('controlTower.pages.cockpitExecution.harvest'),
          accumulated.harvest
        )
      ]

      this.$emit('update')
    },
    mapToKpi(title, item) {
      return {
        title: title,
        type: 'accumulated',
        details: [
          {
            title: 'MI',
            value: item.mi,
            unity: item.unity
          },
          {
            title: 'ME',
            value: item.me,
            unity: item.unity
          }
        ]
      }
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.loadData()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.cockpit-execution-gerencial-information-accumulated {
  &__container {
    padding: 0;
  }
}
</style>
