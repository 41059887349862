<template>
  <cockpit-execution-instructions-status-base
    class="cockpit-execution-instructions-status-stuffing"
    update-event-name="UpdateStuffingInstructionStatus"
    :title="$t('controlTower.pages.cockpitExecution.stuffingTitle')"
    :gauge-label="
      $t('controlTower.pages.cockpitExecution.accumulatedStuffingVolume')
    "
    :base-filter="baseFilter"
    :on-load="loadData"
  >
  </cockpit-execution-instructions-status-base>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import service from '../../api/cockpitService.js'
import CockpitExecutionInstructionsStatusBase from './CockpitExecutionInstructionsStatusBase.vue'

export default defineComponent({
  name: 'CockpitExecutionInstructionsStatusStuffing',
  components: {
    CockpitExecutionInstructionsStatusBase
  },
  props: {
    baseFilter: {
      type: Object,
      required: true
    }
  },
  methods: {
    async loadData(filter) {
      const result = await service.getStuffingInstructionStatus(filter)
      this.$emit('update')
      return result
    }
  }
})
</script>
