<template>
  <cargill-section-header class="cockpit-execution-header">
    <div>
      {{ $t('controlTower.pages.cockpitExecution.title') }}
    </div>

    <div class="cockpit-execution-header__actions">
      <div class="cockpit-execution-header__action">
        <cargill-section-header-btn icon="fas fa-sync-alt" @click="onSync">
        </cargill-section-header-btn>

        <div>
          {{
            `${$t(
              'controlTower.pages.cockpitExecution.lastUpdate'
            )} ${lastUpdateFormatted}`
          }}
        </div>
      </div>

      <bc-divider vertical class="ml-2"></bc-divider>

      <div class="cockpit-execution-header__action">
        <dashboard-filter
          :filters="filterDefinition"
          v-model="$filter"
        ></dashboard-filter>
      </div>
    </div>
  </cargill-section-header>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BcDivider } from '@brain/core'

import {
  CargillSectionHeader,
  CargillSectionHeaderBtn
} from '../cargill-section'
import { DashboardFilter } from '../dashboards'
import { DateFormat, useDateFormatter } from '../../utils/dateFormatter'

export default defineComponent({
  name: 'CockpitExecutionHeader',
  components: {
    BcDivider,
    CargillSectionHeader,
    CargillSectionHeaderBtn,
    DashboardFilter
  },
  props: {
    filterDefinition: {
      type: Array,
      required: true
    },
    lastUpdate: {
      type: Date,
      default: () => new Date()
    }
  },
  data() {
    return {
      filter: {},
      formatDate: useDateFormatter(this)
    }
  },
  methods: {
    onSync() {
      this.$emit('sync')
    }
  },
  computed: {
    lastUpdateFormatted() {
      return this.formatDate(this.lastUpdate, DateFormat.DateTime) + ' HRS'
    },
    $filter: {
      get() {
        return this.filter
      },
      set(newValue) {
        this.$emit('filter-change', { ...newValue })
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.cockpit-execution-header {
  display: flex;
  justify-content: space-between;
  font-size: 16px;

  &__actions,
  &__action {
    display: flex;
    align-items: center;
  }

  &__action {
    font-weight: normal;

    &:last-child {
      margin-right: -8px;
    }
  }
}
</style>
