import {
  dashboardUtils,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import { api } from '@cargill/shared'
import _ from 'lodash'

const baseURL = '/api/controltower/cockpit'

const service = {}

service.getFulfilledDemand = async (_baseFilter) => {
  const url = `${baseURL}/getFulfilledDemand`
  return await dashboardUtils.filterDashboard(_baseFilter, url)
}

service.getActualWeek = async (_baseFilter) => {
  const url = `${baseURL}/getActualWeek`
  return await dashboardUtils.filterDashboard(_baseFilter, url)
}

service.getLoadingInstructionStatus = async (_baseFilter) => {
  const url = `${baseURL}/getLoadingInstructionStatus`
  return await dashboardUtils.filterDashboard(_baseFilter, url)
}

service.getStuffingInstructionStatus = async (_baseFilter) => {
  const url = `${baseURL}/getStuffingInstructionStatus`
  return await dashboardUtils.filterDashboard(_baseFilter, url)
}

service.getBoardingInstructionStatus = async (_baseFilter) => {
  const url = `${baseURL}/getBoardingInstructionStatus`
  return await dashboardUtils.filterDashboard(_baseFilter, url)
}

service.getCockpitNotifications = async (_baseFilter) => {
  const url = `${baseURL}/getCockpitNotifications`
  return await dashboardUtils.filterDashboard(_baseFilter, url)
}

service.getInstructionsTableDetails = async (_baseFilter) => {
  const url = `${baseURL}/getInstructionsTableDetails`
  return await dashboardUtils.filterDashboard(_baseFilter, url)
}

service.getMetaData = async () => {
  try {
    const url = `${baseURL}/getMetaData`
    const response = await api.get(url)
    const validationContext = await service.getValidationContext()
    service.processValidationContext(
      null,
      validationContext,
      response.data['subMaster']
    )
    return response
  } catch (err) {
    console.error(`Failed to get getMetaData`, err)
  }
}

service.getInstructionTableDetailCount = async (
  _baseFilter,
  endpoint,
  keyLevel
) => {
  try {
    const url = `${baseURL}/${endpoint}Count?${_baseFilter}`
    var response = await api.get(url, { params: keyLevel })
    return response.data
  } catch (err) {
    console.error(`Failed to get ${endpoint}Count`, err)
  }
}

service.getInstructionsTableDetailsData = async (
  _baseFilter,
  endpoint,
  keyLevel
) => {
  try {
    const url = `${baseURL}/${endpoint}?${_baseFilter}`
    var response = await api.get(url, {
      params: keyLevel
    })
    return response.data
  } catch (err) {
    console.error(`Failed to get ${endpoint}`, err)
  }
}

const getResponseFilename = (response) => {
  const headerPart = response.headers['content-disposition'].split("''")

  const filename =
    Array.isArray(headerPart) && headerPart.length > 1 ? headerPart[1] : null

  return decodeURIComponent(filename)
}

service.exportDetails = async (_baseFilter) => {
  try {
    const url = `${baseURL}/export?${_baseFilter}`
    var response = await api.get(url, {
      responseType: 'blob'
    })
    const filename = getResponseFilename(response)

    return { data: response.data, filename }
  } catch (err) {
    console.error(`Failed to Export Data from InstructionDetailsTable`, err)
  }
}

service.createCrudService = (endpoint, masterName) => {
  const url = `/api/controltower/${endpoint}`
  const service = createCrudService(url, api, { masterName: masterName })
  return service
}

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.bogeyTableUnity.options = ctx.bogeyTableUnityOptions
  fieldsById.contractedFreightUnity.options = ctx.contractedFreightUnityOptions
}

service.getValidationContext = async () => {
  try {
    const response = await api.get(`${baseURL}/validationContext`)
    return response?.data
  } catch (exception) {
    console.log(exception)
    return null
  }
}

export default service
