<template>
  <div class="renderer-wrapper">
    <bc-grid
      ref="gridComponent"
      :gridOptions="{
        pagination: false,
        getRowHeight,
        ...defaultGridOptions
      }"
      :columnDefs="columnDefsDetail"
      :getItems="getItems"
      :countItems="getDataCount"
      :style="`height: 100%; width: 100%`"
      :detailCellRendererFramework="frameworkCellRenderer"
      :detailCellRendererParams="{
        mainKeys,
        mainKeysLevel,
        parentLevelKey,
        keyLevel: this.currentLevel,
        metaKey,
        meta
      }"
      :masterDetail="true"
      :selectable="false"
      :pageSize="13"
      :customSetSize="customSetSize"
      mode="serverSide"
      @rowGroupOpened="onGroupOpened"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { createCrudColumns, BcGrid } from '@brain/core'
import { metadataHelper, gridUtils } from '@cargill/shared'

import service from '../../api/cockpitService.js'
import CockpitExecutionInstructionsDetailsTableCellRenderer from './CockpitExecutionInstructionsDetailsTableCellRenderer.vue'
import CockpitExecutionInstructionsDetailsTableFrameworkCellRenderer from './CockpitExecutionInstructionsDetailsTableFrameworkCellRenderer.vue'

export default {
  name: 'CockpitExecutionInstructionsDetailsTableFrameworkCellRenderer',
  components: {
    BcGrid
  },
  data() {
    return {
      frameworkCellRenderer: null,
      columnDefsDetail: null,
      mainKeys: null,
      mainKeysLevel: null,
      currentLevel: null,
      keyLevel: null,
      parentLevelKey: null,
      meta: null,
      metaKey: null,
      defaultGridOptions: gridUtils.defaultGridOptions
    }
  },
  beforeMount() {
    this.frameworkCellRenderer = Vue.extend(
      CockpitExecutionInstructionsDetailsTableFrameworkCellRenderer
    )
    this.mainKeys = this.params.mainKeys
    this.mainKeysLevel = this.params.mainKeysLevel // endpoints and parent grouping keys
    this.metaKey = `sub${this.capitalizeFirstLetter(this.params.metaKey)}`
    this.meta = this.params.meta
    this.currentLevel = this.defineLevel(this.params) // current level key
    this.parentLevelKey = this.createGroupKey(this.params) // associate parent grouping keys to its values

    this.columnDefsDetail = this.createColumns(this.meta[this.metaKey])
    this.tableReady = true
  },
  mounted() {},
  methods: {
    customSetSize(aggridOptions) {
      gridUtils.resize(aggridOptions)
    },
    translate(key) {
      return this.$t(`controlTower.pages.cockpitExecution.table.${key}`)
    },
    createColumns(metadata) {
      const crudColumns = createCrudColumns(
        metadata,
        'serverSide',
        this.translate
      ).filter((x) => x.key != 'id' && x.key != 'details')
      metadataHelper.defineCellRendererCockpit(
        crudColumns,
        CockpitExecutionInstructionsDetailsTableCellRenderer
      )
      return crudColumns
    },
    onGroupOpened(event) {
      this.keyLevel = event.data[this.currentLevel]
    },
    createGroupKey({ data, mainKeysLevel, parentLevelKey }) {
      const { parentData } = mainKeysLevel[this.currentLevel]
      const obj = {}

      parentData.forEach((key) => {
        if (typeof data[key] === 'object' && data[key] !== null) {
          obj[key] = data[key].value
        } else {
          obj[key] = data[key]
        }
      })

      return { ...obj, ...parentLevelKey }
    },
    defineLevel({ mainKeysLevel, meta }) {
      return metadataHelper.defineLevel(meta[this.metaKey], mainKeysLevel)
    },
    getRowHeight(params) {
      var isDetailRow = params.node.detail
      if (!isDetailRow) {
        return undefined
      }
      return 300
    },
    getDataCount(filter) {
      const { endpoint } = this.mainKeysLevel[this.currentLevel]
      return service.getInstructionTableDetailCount(
        filter,
        endpoint,
        this.parentLevelKey
      )
    },
    async getItems(params) {
      const { endpoint, levelService } = this.mainKeysLevel[this.currentLevel]
      const data = await service.getInstructionsTableDetailsData(
        params,
        endpoint,
        this.parentLevelKey
      )
      if (levelService?.getPostProcessing) {
        levelService.getPostProcessing(data)
      }
      return data
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
  }
}
</script>

<style lang="scss" scoped>
.renderer-wrapper {
  margin: 5px 0 0 40px;
  height: 100%;
  padding-bottom: 5px;
}
</style>
