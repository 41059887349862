var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"instructions-table-container"},[(_vm.tableReady)?_c('bc-grid',{ref:"gridComponent",style:("height: 650px; width: 100%"),attrs:{"gridOptions":Object.assign({}, {getRowHeight: _vm.getRowHeight,
      rowModelType: 'serverSide'},
      _vm.defaultGridOptions),"columnDefs":_vm.columns,"getItems":_vm.getItems,"countItems":_vm.getDataCount,"mainKeys":_vm.mainKeys,"detailCellRendererFramework":_vm.frameworkCellRenderer,"detailCellRendererParams":{
      mainKeys: _vm.mainKeys,
      mainKeysLevel: _vm.mainKeysLevel,
      parentLevelKey: {},
      keyLevel: this.level,
      metaKey: 'master',
      meta: _vm.meta
    },"selectable":false,"pageSize":13,"customSetSize":_vm.customSetSize,"masterDetail":true,"mode":"serverSide"},on:{"rowGroupOpened":_vm.onGroupOpened}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }