<template>
  <bc-container class="cockpit-execution-gerencial-information">
    <cargill-section>
      <cargill-section-header
        class="cockpit-execution-gerencial-information__header"
        color="#2B323F"
      >
        {{ $t('controlTower.pages.cockpitExecution.gerencialInformations') }}
      </cargill-section-header>
    </cargill-section>

    <bc-row>
      <bc-col cols="12" lg="4">
        <cockpit-execution-gerencial-information-accumulated
          :filter="filter"
          @update="onUpdate"
        >
        </cockpit-execution-gerencial-information-accumulated>
      </bc-col>

      <bc-col cols="12" lg="8">
        <cockpit-execution-gerencial-information-week
          :filter="filter"
          @update="onUpdate"
        >
        </cockpit-execution-gerencial-information-week>
      </bc-col>
    </bc-row>
  </bc-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BcContainer, BcRow } from '@brain/core'

import {
  CargillSection,
  CargillSectionHeader,
  CargillSectionContent
} from '../cargill-section'
import CockpitExecutionGerencialInformationAccumulated from './CockpitExecutionGerencialInformationAccumulated.vue'
import CockpitExecutionGerencialInformationWeek from './CockpitExecutionGerencialInformationWeek.vue'

export default defineComponent({
  name: 'CockpitExecutionGerencialInformation',
  components: {
    BcContainer,
    BcRow,
    CargillSection,
    CargillSectionHeader,
    CargillSectionContent,
    CockpitExecutionGerencialInformationAccumulated,
    CockpitExecutionGerencialInformationWeek
  },
  props: {
    filter: {
      type: Object,
      required: true
    }
  },
  methods: {
    onUpdate() {
      this.$emit('update')
    }
  }
})
</script>

<style lang="scss" scoped>
.cockpit-execution-gerencial-information {
  padding: 10px 0 0;

  &__header {
    margin-bottom: 10px;
  }
}
</style>
