<template>
  <div class="cockpit-execution-instructions-details-legend">
    <div class="cockpit-execution-instructions-details-legend__title">
      {{ $t('controlTower.pages.cockpitExecution.instructionsLegend') }}:
    </div>
    <div
      class="cockpit-execution-instructions-details-legend__item"
      v-for="legend in legendItems"
      :key="legend.name"
    >
      <div :class="legend.classNames"></div>
      <span class="cockpit-execution-instructions-details-legend__item__label">
        {{ legend.label }}
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'CockpitExecutionInstructionsDetailsLegend',
  data() {
    return {
      legends: [
        {
          name: 'notStarted',
          color: 'grey'
        },
        {
          name: 'onTime',
          color: 'green'
        },
        {
          name: 'riskOfDelay',
          color: 'orange'
        },
        {
          name: 'late',
          color: 'red'
        }
      ]
    }
  },
  computed: {
    legendItems() {
      return this.legends.map((legend) => ({
        name: legend.name,
        label: this.$t(
          `controlTower.pages.cockpitExecution.table.cellRenderLegend.${legend.name}`
        ),
        classNames: [
          'cockpit-execution-instructions-details-legend__item__indicator',
          `cockpit-execution-instructions-details-legend__item__indicator--${legend.color}`
        ]
      }))
    }
  }
})
</script>

<style lang="scss" scoped>
.cockpit-execution-instructions-details-legend {
  display: flex;
  align-items: center;

  &__title {
    font-weight: bold;
    margin-right: 10px;
  }

  &__item {
    display: flex;
    align-items: center;
    font-weight: normal;

    &:not(:last-of-type) {
      margin-right: 20px;
    }

    &__indicator {
      width: 14px;
      height: 14px;
      border-radius: 50px;
      margin-top: -2px;
      margin-right: 8px;

      &--grey {
        background-color: #959db5;
        box-shadow: 0px 0px 6px #959db580;
      }
      &--green {
        background-color: #81dd1f;
        box-shadow: 0px 0px 6px #81dd1f80;
      }
      &--orange {
        background-color: #ff9a00;
        box-shadow: 0px 0px 6px #ff9a0080;
      }
      &--red {
        background-color: #de0b3c;
        box-shadow: 0px 0px 6px #de0b3c80;
      }
    }
  }
}
</style>
